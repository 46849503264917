<script>

    export let smallText = 'We offer effective feedback.';
    export let bigText = 'Instantly';
    export let image = `${base}/images/13.png`;
    
    import {base} from "$app/paths";

</script>

<div class="banner">
    <div class="text">
        <p>{smallText}</p>
        <h1><strong>{bigText}</strong><strong style="color: #605DF9;">.</strong></h1>
    </div>
    <div class="image">

        <img src={image} alt='description' width="50%"/>

    </div>
</div>
  
  
<style>
 .banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ensures text and image are at opposite ends */
    align-items: center; /* Vertically centers the content */
    padding: 0 10vw; /* Adjusts padding to ensure content is not too close to the edges */
    height: 45vh;
    width: 80vw;
    margin: auto; /* Centers the banner if the viewport width is larger than the banner */
    background-image: linear-gradient(to right, #1e1e1e 75%, #605DF9 25%);
    color: #2e2e2e;
  }

  .text {
    color: white; /* Ensures text color is white */
    text-align: left; /* Aligns the text to the left */
    font-size: 3.5vh;
  }

  .image img {
    width: auto; /* Maintain the image's aspect ratio */
    max-width: 90%; /* Allow the image to fill its container */
    height: auto; /* Adjust height automatically */
   
  }

  .image  {
   
    display: flex;
    justify-content: flex-end; /* Align the image to the far right */
    width: 100%; /* Ensure the container takes full available width */
    max-width: 38%; 
    order: -1;

    
  }

  @media (max-width: 600px) {
 .banner{
  background-image: linear-gradient(to top, #1e1e1e 50%, #605DF9 50%);
  width: 100vw;
  justify-content: center;
  height: 55vh;
  
 }

 .text {
    color: white; /* Ensures text color is white */
    text-align: center; /* Aligns the text to the left */
    font-size: 2.5vh;
   
  }

  .image  {
   
   display: flex;
   justify-content: center; /* Align the image to the far right */
   width: 100%; /* Ensure the container takes full available width */
   max-width: 75%; 
   order: -1;

   
}
}
</style>
  