<script>

  import {createEventDispatcher} from "svelte";

  export let title ;
  export let price
  export let image = '';
  export let background_gradient = 'linear-gradient(to bottom, #605DF9, #000000)';
  export let items;
  export let button_content = 'Get Started'
  export let followup_link;

  const disp = createEventDispatcher()

</script>

<div class="card" style="background-image: {background_gradient};" >

  <div class="image">

    <img src={image} alt="math" width="75%" >

  </div>

  <div class="text">
    <h1 style="font-weight: bold; font-size: 30px">{title}</h1>
    {#if price}
      <h2 style="font-weight: bold; font-size: 20px; color: yellow;" >{price}</h2>
    {/if}
    <ul class="list" style="text-align: left; line-height: 1.8; text-align: left; ">
      {#each items as item}
        <li style=" padding-bottom: 10px;">{item}</li>
      {/each}
    </ul>
    {#if followup_link}
    <a href={followup_link}>
    <button class="outlined-button">{button_content}</button>
    </a>
      {:else}
      <a href="#">
        <button class="outlined-button" on:click|preventDefault={ev=>{disp('click')}}>{button_content}</button>
      </a>

    {/if}
  </div>

</div>


<style>
.card {
 display: flex;
 flex-direction: column;
 padding: 5vw;
 justify-content: center; /* Vertically center the content if the card's height allows */
 height: auto;
 width: 20vw;
 gap: 1vw;
 margin: auto; /* Center the card itself if within another container */
 text-align: center; /* Ensures text alignment is centered */
 color: white; /* Assuming you want white text */
 background-image: linear-gradient(to bottom, #605DF9, #000000);
 border-radius: 30px;
}

.outlined-button{
   padding: 10px 20px;
   border: 2px solid white;
   border-radius: 20px;
   background-color: black;
   color: white;
   font-size: 16px;
   text-transform: uppercase;
   cursor: pointer;
   transition: all 0.3s ease;
   margin:5px;
   width: 100%;
 }

 @media (max-width: 600px) {

.card{
  width: 100%;
  margin: 0;

}
}




</style>