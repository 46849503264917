<script>/**
 * Embeds Calendly directly within the page using an iframe.
 * This approach keeps users on-site, providing a seamless scheduling experience.
 */
  import {onMount} from "svelte";

export let calendly_url = "https://calendly.com/edup-ai/product-preview"; // Your Calendly URL

  onMount(()=>{
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = "https://assets.calendly.com/assets/external/widget.js"

      //`${import.meta.env.VITE_API_URL}/static/memvp/transmittable_message.js`;
      script.async = true;
      script.onload = () => {
        console.log('Script loaded successfully');

        // Handle any actions that need to occur after the script is loaded
        // For example, accessing something now available on `window`
        // if (window.myLibrary) { ... }
      };
      script.onerror = (error) => {
        console.error('Script failed to load', error);
      };

      // Append the script to the document's head
      document.head.appendChild(script);

      // Optional: Remove the script when the component is destroyed
      // to clean up resources.
      // return () => {
      //   document.head.removeChild(script);
      // };
    }
  })
</script>

<style>
  .calendly-embed {
    width: 100%; /* Full width to fit the container */
    height: 600px; /* Adjust height as needed for your layout */
    border: none; /* Remove default iframe border */
  }
</style>
<!--
<iframe class="calendly-embed" src="{calendly_url}"></iframe>

 Calendly inline widget begin -->
<div class="calendly-inline-widget" data-url={`${calendly_url}?background_color=1a1a1a&text_color=ffffff&primary_color=af00ff`} style="min-width:320px;height:700px;"></div>
<!--
<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
 Calendly inline widget end -->