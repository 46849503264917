<script>
   
    export let text = 'Upload your handwritten work';
    export let image = '';
    export let background_gradient = 'linear-gradient(to bottom, #605DF9, #000000)';
    export let items = ['Derivatives', 'Trigonometry', 'Integration', 'Substitution by Parts'];
    import {base} from "$app/paths";

    
</script>

<div class="card" style="background-image: {background_gradient};" >

    <div class="image">

        <img src={image} alt="math" width="120vw" style=" opacity: 0.5;">

    </div>

    <div class="text">
        <h1 style="font-weight: bold; font-size: 30px">{text}</h1>
        <ul  class="list" style="text-align: left;  line-height: 1.8;  text-align: left; ">
            {#each items as item}
                <li style=" padding-bottom: 10px;">{item}</li>
            {/each}
        </ul>
    </div>

</div>
  
  
<style>
.card {
  display: flex;
  padding: 5vw;
  justify-content: center; /* Vertically center the content if the card's height allows */
  height: 30vh;
  width: 15vw;
  gap: 1vw;
  margin: auto; /* Center the card itself if within another container */
  text-align: center; /* Ensures text alignment is centered */
  color: white; /* Assuming you want white text */
  background-image: linear-gradient(to bottom, #605DF9, #000000);
  border-radius: 30px;
}



@media (max-width: 600px) {

    .card{
        width: 100%;
        margin: 0 0 0 20vw;
    }
}



</style>